body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.home {
  position: absolute;
  top: 0;
  min-height: 100vh;
  background-image: url('back.jpg');
  background-size: cover;
  max-width: unset !important;
  padding-bottom: 5%;
}
.study {
  min-height: 96vh;
  max-height: 96vh;
  background-image: url('study.jpg');
  background-size: cover;
}

.findOut {
  position: relative;
  top: -25px;
  color: black;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: 5px 5px 5px #3c3c3c;
}

.whiteFont {
  color: white;
}

.icon {
  display: inline-flex;
  vertical-align: text-top;
  padding-top: 2px;
}

.icon-bottom {
  display: inline-flex;
  vertical-align: bottom;
  padding-top: 2px;
}

.header {
  padding-top: 5%;
}

.description {
  font-size: 1.2rem !important;
  line-height: 1.5 !important;
}

.ref {
  padding-left: 1.5em;
  text-indent: -1.5em;
}

.divider {
  width: 40%;
  text-align: center;
  color: black;
  font-weight: bold;
  height: 4px;
  border-radius: 50%;
  background-color: black;
}

.aboutMeImg {
  border-radius: 50%;
  width: 250px;
  float: left;
}

.wrap {
  word-wrap: break-word;
}
.list-box {
  padding-left: 72px;
}

.topButton {
  padding: 5px !important;
  color: white !important;
  background-color: #3c3c3c !important;
}
.headerDivider {
  width: 110%;
  margin: 0 0 0 -10px;
  height: 4px;
  background-color: #3c3c3c;
}
.yearDivider {
  width: 140%;
  margin: 0 0 0 -10px;
  height: 4px;
  background-color: #3c3c3c;
}

.mapDivider {
  width: 100%;
  height: 4px;
  background-color: #3c3c3c;
}

.joinUsButton {
  position: fixed;
  right: 40px;
  top: 120px;
  animation: joinUs 3s infinite alternate;
  background-color: transparent !important;
}

@keyframes joinUs {
  0% {
    transform: scale(1) rotate(-15deg);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1) rotate(15deg);
  }
}

.sectionName {
  width: fit-content;
}

header .MuiLink-root:hover {
  color: white !important;
}

.single-lang {
  min-width: 24%;
  font-size: 1rem;
  padding: 0.3rem 0;
  font-weight: bold;
}

.lg {
  font-size: 25px;
  color: blue;
}

@media (max-width: 450px) {
  .aboutMeImg {
    width: 100%;
    max-width: 250px;
    float: none;
  }
}

@media (max-width: 600px) {
  .list-box {
    padding-left: 20px;
  }
  .single-lang {
    min-width: 100%;
  }
}

@media (max-width: 960px) {
  .joinUsButton {
    position: unset;
    animation: none;
  }
}

.fillWidth {
  width: 100%;
}

.error {
  color: red;
}
.shadow-text {
  color: white;
  text-shadow: 5px 5px #000000;
}

.gif {
  max-width: 100%;
}

.attractiveness .MuiTypography-h6 {
  padding-top: 25px;
}

.underline {
  text-decoration: underline;
}

.languages-list {
  list-style: none;
  columns: 2;
}

.tlsCards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.tlsCard {
  flex-basis: 30%;
  overflow: hidden;
  position: relative;
  width: 275px;
  align-items: center;
  align-items: stretch;
  border-radius: 10px;
  box-shadow: 0px 5px 38px -5px rgba(101, 101, 101, 1);
  display: flex;
  flex-direction: column;
}
.tlsCard.tls {
  flex-basis: 100%;
}
.tlsCard .img {
  height: 180px;
  object-fit: cover;
  width: 100%;
  background-color: red;
}
.tlsCard .result {
  background-color: white;
  font-weight: bold;
  font-size: 25px;
  position: absolute;
  top: 145px;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50%;
  border: 3px solid red;
}

.tlsCard .resultContent {
  padding-top: 35px;
  text-align: center;
  padding: 35px 15px 20px 15px;
}

.tlsCard .title {
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: bold;
}

.tlsCard .resultContent .desc {
  font-size: 13px;
  text-align: justify;
}

small.note {
  color: #b5b5b5;
}
.refList {
  font-size: 0.9rem !important;
}
.tlsCard.tls .resultContent .desc {
  text-align: center;
}
@media (max-width: 992px) {
  .tlsCards {
    flex-wrap: wrap;
  }
  .tlsCard {
    flex-basis: 100%;
  }
  .PrivateSwitchBase-root-2 {
    padding: 4px !important;
  }
  .MuiTypography-body1 {
    font-size: 0.8rem !important;
  }
}

@media (max-width: 524px) {
  .PrivateSwitchBase-root-2 {
    padding: 0px !important;
  }
  .qa .MuiTypography-body1 {
    font-size: 0.6rem !important;
  }
}
