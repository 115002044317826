#game{
	min-height: 100vh;
	background-color: rgba(201, 201, 201, 0.75);
}


.main {
	padding: 10px 20px;
	width: 300px;
	min-height: 300px;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
	font-size: 1.2rem; 
}

.main-end {
	padding: 10px 20px;
	width: 500px;
	min-height: 300px;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
	font-size: 1.2rem; 
}
.mainImage img{
	width: 100%;
}
.mainImage{
	padding: 10px 20px;
	width: 100vw;
	min-height: 300px;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
	font-size: 1.2rem; 
}

.questionnaire {
	padding: 10px 45px;
	width: 90vw;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
}

.questionnaire img {
	width: 80%;
}

.slider {
	width: 80%;
	margin: 20px auto 50px auto;
}

.slider span.MuiSlider-markLabel {
	white-space: initial;
	max-width: 150px;
 }

  .drawer-background{
	background-color: transparent !important;
	width: 50vw;
	border: 0;
 }

 @media(min-width: 1200px){
 .drawer-background{
	background-color: grey !important;
	width: 16vw;
 }
 .questionnaire {
	padding: 10px 30px;
	width: 600px;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
}
 .left-top{
	position: absolute;
	left: 16vw;
	top: 0;
}
.right-top{
	position: absolute;
	right: 16vw;
	top: 0;
}
.left-bottom{
	position: absolute;
	left: 16vw;
	bottom: 0;
}
.right-bottom{
	position: absolute;
	right: 16vw;
	bottom: 0;
}
.mainImage{
	padding: 10px 20px;
	width: 600px;
	min-height: 300px;
	background-color: white;
	box-shadow: 0px 0px 44px -5px rgba(0,0,0,0.75);
	font-size: 1.2rem; 
}
}

 .gauge{
	 position: absolute;
	 bottom: 0;
 }

 .gauge .percent-text{
	 font-size: 42px !important; 
	 font-weight: bold;
 }

 .MuiButton-root{
	text-transform: initial !important;
}

.nickName{
	color: blue;
}
.radioGroupCenter{
	justify-content: center;
}

.ageFormControl{
	min-width: 15vw !important;
}

.comment-box .comment{
	border-radius: 20px;
	padding: 10px;
	background-color: lightgray;
}

.comment-box-left{
	position: absolute;
    left: 0;
    top: 20vh;
    z-index: 100093;
}

.comment-box-right{
	position: absolute;
    right: 0;
    top: 20vh;
    z-index: 100093;
}

.comment-box .main{
	background-color: transparent;
	box-shadow: none;
}


.comment-box .comment p{
	margin: 0;
}

.comment-subsection{
	font-style: italic;
	padding-left: 10px;
}

.avatar{
	margin-top: 10px;
}

.MuiAvatar-img{
	border-radius: 50%;
	border: 1px solid black;
}

.image-fly{
	position: absolute;
	z-index: -10;
	animation-iteration-count: infinite;
}

@keyframes falling {
	0% {opacity: 0}
	10% {opacity: 1}

	100% {top: 80vh;
		opacity: 0};
  }


.likes-left{
	opacity: 0;
	position: absolute;
	overflow: hidden;
}
.likes-right{
	opacity: 0;
	position: absolute;
	overflow: hidden;
}

#playerGauge svg, #monicaGauge svg{
	width: 100% !important;
}

.MuiDrawer-paperAnchorDockedRight, .MuiDrawer-paperAnchorDockedLeft{
	border-right: 0 !important;
	border-left: 0 !important;
	z-index: 0;
	overflow: hidden;
}

.MuiDrawer-docked .MuiDrawer-paper{
	z-index: 0;
}